<template>
  <div class="main-content">
    <div class="layout-px-spacing">
      <div class="row layout-top-spacing">
        <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
          <div class="text-right">
            <button
              type="button"
              class="mb-2 mr-2 btn btn-primary"
              data-toggle="modal"
              data-target="#filterModal"
            >
              <i class="fa fa-filter"></i>
            </button>
          </div>
          <div class="widget-content widget-content-area br-6">
            <div v-if="loading">
              <b-skeleton-table
                :rows="10"
                :columns="5"
                :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>
            </div>

            <div v-else class="mt-4 mb-4 table-responsive">
              <table
                id="INVESTIGATION_LOGS"
                class="table table-hover non-hover"
                style="width: 100%"
              >
                <thead>
                  <tr>
                    <th>Patient Name</th>
                    <th>Investigation</th>
                    <th>Date Requested</th>
                    <th>Date Payment Made</th>
                    <th>Date Test Done</th>
                    <th>Date Results Provided</th>
                    <th>Date Uploaded to File</th>
                    <th>Date Recommendation fulfilled</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="investigationLog in investigationLogs"
                    :key="investigationLog.id"
                  >
                    <td>
                      {{ investigationLog.elderly_name }}
                    </td>
                    <td>
                      {{ investigationLog.description }}
                    </td>
                    <td>{{ formatDateTime(investigationLog.created_at) }}</td>
                    <td>{{ formatDateTime(investigationLog.date_paid) }}</td>
                    <td>{{ investigationLog.test_done }}</td>
                    <td>{{ investigationLog.test_provided }}</td>
                    <td>{{ investigationLog.uploaded_file }}</td>
                    <td>{{ investigationLog.recommendation_fulfilled }}</td>
                    <td>
                      <button
                        class="btn btn-success btn-sm"
                        data-toggle="modal"
                        data-target="#dateModal"
                        @click="setId(investigationLog.id)"
                      >
                        <span> <i class="fa fa-wrench"></i></span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Search Modal -->
    <div
      class="modal fade"
      id="filterModal"
      role="dialog"
      aria-labelledby="filterModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="filterModalLabel">Search Options</h5>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="dateSearch">Filter</label>
                  <input
                    class="form-control"
                    type="date"
                    name="date"
                    id="date"
                    v-model="filter"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn" data-dismiss="modal">
              <i class="flaticon-cancel-12"></i> Discard
            </button>
            <button
              type="button"
              class="btn btn-primary"
              @click="handleFilter()"
            >
              Filter
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Date selector -->
    <div
      class="modal fade"
      id="dateModal"
      role="dialog"
      aria-labelledby="dateModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="dateModalLabel">Select date</h5>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="action">Select Action</label>
                  <select
                    name="action"
                    id="action"
                    class="form-control"
                    v-model="action"
                  >
                    <option
                      v-for="(action, index) in actions"
                      :key="index"
                      :value="action"
                    >
                      {{ action.split("_").join(" ").toUpperCase() }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label for="dateSearch">Select Date</label>
                  <input
                    class="form-control"
                    type="date"
                    name="date"
                    id="date"
                    v-model="date"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn" data-dismiss="modal">
              <i class="flaticon-cancel-12"></i> Discard
            </button>
            <button type="button" class="btn btn-primary" @click="storeLog()">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from "@/utils/http";
import $ from "jquery";
import { endpoints } from "@/utils/endpoints";

export default {
  name: "InvestigationLogs",

  data() {
    return {
      loading: false,
      investigationLogs: [],
      filter: "",
      date: "",
      action: "",
      invoice_item_id: "",
      actions: [
        "test_done",
        "test_provided",
        "uploaded_file",
        "recommendation_fulfilled",
      ],
    };
  },

  mounted() {
    if (this.$route.query) {
      this.filter = this.$route.query.date;
    }

    this.fetchInvestigation();
  },

  methods: {
    setId(value) {
      this.invoice_item_id = value;
    },

    initTable() {
      var dt = $("#INVESTIGATION_LOGS").DataTable({
        retrieve: true,
        dom: "Bfrtip",
        stateSave: true,
        colReorder: true,
        order: [[3, "desc"]],
        buttons: [
          {
            extend: "copyHtml5",
            text: 'Copy <i class="fa fa-clone"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "excelHtml5",
            text: 'Excel <i class="fa fa-file-excel"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "pdfHtml5",
            text: 'PDF <i class="fa fa-file-pdf"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "print",
            text: 'Print <i class="fa fa-print"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          "colvis",
        ],
        select: true,
      });
      dt.columns([4]).visible(false);
    },

    fetchInvestigation() {
      this.loading = true;
      let url = endpoints.INVESTIGATION_LOGS + `?date=${this.filter}`;

      http
        .get(url)
        .then((response) => {
          this.investigationLogs = response;
          this.loading = false;

          setTimeout(() => {
            this.initTable();
          }, 1000);
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },

    storeLog() {
      if (!this.date || !this.action) {
        this.$toast.error("Date and action are required!");
        return;
      }
      this.loading = true;
      let url = endpoints.INVESTIGATION_LOGS_STORE;
      http
        .post(url, {
          invoice_item_id: this.invoice_item_id,
          date: this.date,
          action: this.action,
        })
        .then((response) => {
          this.loading = false;
          this.$toast.success(response.message);

          this.fetchInvestigation();
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },

    handleFilter() {
      this.fetchInvestigation();
      $("#filterModal").modal("hide");
    },
  },
};
</script>

<style>
.select2-container {
  width: 60% !important;
}
.new-control {
  font-weight: 400;
  font-size: 15px;
}
</style>
